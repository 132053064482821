import React, { Component } from "react"

class Privacy extends Component {
  render() {
    return (
      <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 pt-4 pb-16 bg-white text-gray-800">
        <h1 className="mt-20 text-3xl font-bold">
          Datenschutz&shy;er&shy;klä&shy;rung
        </h1>
        <h2 className="text-3xl font-bold mt-3 mb-2">
          1. Datenschutz auf einen Blick
        </h2>
        <h2 className="text-3xl mt-3 mb-2">Allgemeine Hinweise</h2>
        <span className="space-y-3">
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">
          Datenerfassung auf unserer Website
        </h2>
        <span className="space-y-3">
          <p>
            <strong>
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </strong>
          </p>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
            dieser Website entnehmen.
          </p>
          <p>
            <strong>Wie erfassen wir Ihre Daten?</strong>
          </p>
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </p>
          <p>
            <strong>Wofür nutzen wir Ihre Daten?</strong>
          </p>
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>
          <p>
            <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
          </p>
          <p>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
            oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
            Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
            Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>
          <p>
            Außerdem haben Sie das Recht, unter bestimmten Umständen die
            Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung
            unter „Recht auf Einschränkung der Verarbeitung“.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">
          Analyse-Tools und Tools von Drittanbietern
        </h2>
        <span className="space-y-3">
          <p>
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit Cookies und mit
            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
            erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
            zurückverfolgt werden. Sie können dieser Analyse widersprechen oder
            sie durch die Nichtbenutzung bestimmter Tools verhindern.
            Detaillierte Informationen dazu finden Sie in der folgenden
            Datenschutzerklärung.
          </p>
          <p>
            Sie können dieser Analyse widersprechen. Über die
            Widerspruchsmöglichkeiten werden wir Sie in dieser
            Datenschutzerklärung informieren.
          </p>
        </span>
        <h2 className="text-3xl font-bold mt-8 mb-2">
          2. Allgemeine Hin&shy;wei&shy;se und
          Pflicht&shy;in&shy;for&shy;ma&shy;tio&shy;nen
        </h2>
        <h2 className="text-3xl mt-3 mb-2">Datenschutz</h2>
        <span className="space-y-3">
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">
          Hinweis zur verantwortlichen Stelle
        </h2>
        <span className="space-y-3">
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </p>
          <p>
            Eduard Smolarczyk
            <br />
            Unter den Krohgärten 20
            <br />
            35288 Wohratal
          </p>
          <p>
            Telefon: +49 151 50 583 582
            <br />
            E-Mail: mail@e-smolarczyk.de
          </p>
          <p>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z.B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </h2>
        <span className="space-y-3">
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </h2>
        <span className="space-y-3">
          <p>
            Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e
            oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen,
            die sich aus Ihrer besonderen Situation ergeben, gegen die
            Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen;
            dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
            entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch
            einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht
            mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige
            Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
            und Freiheiten überwiegen oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            (Widerspruch nach Art. 21 Abs. 1 DSGVO).
          </p>
          <p>
            Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
            betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
            Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
            derartiger Werbung einzulegen; dies gilt auch für das Profiling,
            soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie
            widersprechen, werden Ihre personenbezogenen Daten anschließend
            nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach
            Art. 21 Abs. 2 DSGVO).
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">
          Beschwerderecht bei der zuständigen Aufsichtsbehörde
        </h2>
        <span className="space-y-3">
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">
          Auskunft, Sperrung, Löschung und Berichtigung
        </h2>
        <span className="space-y-3">
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">
          Recht auf Einschränkung der Verarbeitung
        </h2>
        <span className="space-y-3">
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
            Fällen:
          </p>
          <ul className="list-disc pl-10">
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah / geschieht, können Sie statt der Löschung die
              Einschränkung der Datenverarbeitung verlangen.
            </li>
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
        </span>
        <h2 className="text-3xl font-bold mt-8 mb-2">
          3. Datenerfassung auf unserer Website
        </h2>
        <h2 className="text-3xl mt-3 mb-2">Kontaktformular</h2>
        <span className="space-y-3">
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten
            erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung (Art.
            6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
            widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
            Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen – insbesondere
            Aufbewahrungsfristen – bleiben unberührt.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">
          Anfrage per E-Mail, Telefon oder Telefax
        </h2>
        <span className="space-y-3">
          <p>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und / oder auf
            unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir
            ein berechtigtes Interesse an der effektiven Bearbeitung der an uns
            gerichteten Anfragen haben.
          </p>
          <p>
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
            verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
            Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere
            gesetzliche Aufbewahrungsfristen – bleiben unberührt.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">
          Verarbeiten von Daten (Kunden- und Vertragsdaten)
        </h2>
        <span className="space-y-3">
          <p>
            Wir erheben, verarbeiten und nutzen personenbezogene Daten nur,
            soweit sie für die Begründung, inhaltliche Ausgestaltung oder
            Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten).
            Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die
            Verarbeitung von Daten zur Erfüllung eines Vertrags oder
            vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über
            die Inanspruchnahme unserer Internetseiten (Nutzungsdaten) erheben,
            verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem
            Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder
            abzurechnen.
          </p>
          <p>
            Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
            Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
            Aufbewahrungsfristen bleiben unberührt.
          </p>
        </span>
        <h2 className="text-3xl font-bold mt-8 mb-2">4. Plugins und Tools</h2>
        <h2 className="text-3xl mt-3 mb-2">Google Web Fonts</h2>
        <span className="space-y-3">
          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts, die von Google bereitgestellt werden. Die Google
            Fonts sind lokal installiert. Eine Verbindung zu Servern von Google
            findet dabei nicht statt.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">Google Analytics</h2>
        <span className="space-y-3">
          <p>
            Wir verwenden Google Analytics, um die Website-Nutzung zu
            analysieren. Die daraus gewonnenen Daten werden genutzt, um unsere
            Website sowie Werbemaßnahmen zu optimieren.
          </p>

          <p>
            Google Analytics ist ein Webanalysedienst, der von
            <em>Google Inc.</em> (1600 Amphitheatre Parkway, Mountain View, CA
            94043, United States) betrieben und bereitgestellt wird. Google
            verarbeitet die Daten zur Website-Nutzung in unserem Auftrag und
            verpflichtet sich vertraglich zu Maßnahmen, um die Vertraulichkeit
            der verarbeiteten Daten zu gewährleisten.
          </p>

          <p>
            Während Ihres Website-Besuchs werden u.a. folgende Daten
            aufgezeichnet:
          </p>

          <ul className="list-disc pl-10">
            <li>Aufgerufene Seiten</li>
            <li>Die Erreichung von "Website-Zielen" (z.B. Kontaktanfragenn)</li>
            <li>
              Ihr Verhalten auf den Seiten (beispielsweise Klicks,
              Scroll-Verhalten und Verweildauer)
            </li>
            <li>Ihr ungefährer Standort (Land und Stadt)</li>
            <li>
              Ihre IP-Adresse (in gekürzter Form, sodass keine eindeutige
              Zuordnung möglich ist)
            </li>
            <li>
              Technische Informationen wie Browser, Internetanbieter, Endgerät
              und Bildschirmauflösung
            </li>
            <li>
              Herkunftsquelle Ihres Besuchs (d.h. über welche Website bzw. über
              welches Werbemittel Sie zu uns gekommen sind)
            </li>
          </ul>

          <p>
            Diese Daten werden an einen Server von Google in den USA übertragen.
          </p>

          <p>
            Google Analytics speichert Cookies in Ihrem Webbrowser für die Dauer
            von zwei Jahren seit Ihrem letzten Besuch. Diese Cookies enthaltene
            eine zufallsgenerierte User-ID, mit der Sie bei zukünftigen
            Website-Besuchen wiedererkannt werden können.
          </p>

          <p>
            Die aufgezeichneten Daten werden zusammen mit der zufallsgenerierten
            User-ID gespeichert, was die Auswertung pseudonymer Nutzerprofile
            ermöglicht. Diese nutzerbezogenen Daten werden automatisch nach 14
            Monaten gelöscht. Sonstige Daten bleiben in aggregierter Form
            unbefristet gespeichert.
          </p>

          <p>
            Sollten Sie mit der Erfassung nicht einverstanden sein, können Sie
            diese mit der einmaligen Installation des{" "}
            <a
              className="text-blue-400 hover:underline"
              href="https://tools.google.com/dlpage/gaoptout?hl=de"
              target="_blank"
              rel="noreferrer"
            >
              Browser-Add-ons zur Deaktivierung von Google Analytics
            </a>{" "}
            unterbinden oder durch das Ablehnen der Cookies über unseren Cookie
            Einstellungs Dialog .
          </p>
        </span>
      </div>
    )
  }
}

export default Privacy
