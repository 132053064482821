import React, { Component } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Privacy from "../components/privacy"

class Index extends Component {
  render() {
    return (
      <Layout spacing="true">
        <Seo title="Datenschutz" />
        <Privacy />
      </Layout>
    )
  }
}

export default Index
